<template>
  <div ref="chart" class="SentimentOverTimeChart" />
</template>

<script>
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import { SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'
import { blue, salmon, grey } from '@hypefactors/shared/js/components/charts/DefaultChartColors'

/**
 * @module SentimentOverTimeChart
 */
export default {
  name: 'SentimentOverTimeChart',
  props: {
    /**
     * @type {{ neutral: String, positive: String, negative: String }}
     */
    chartData: {
      type: Object,
      required: true
    }
  },
  watch: {
    chartData: 'redrawChart'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      this.$chart = new AreaChart(this.$refs.chart, this.createSeries(), {
        title: {
          text: ''
        },
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'top',
          floating: false
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        yAxisLabel: 'Clippings'
      })
    },
    createSeries () {
      /**
       * Goes over the entries and stacks them into a collection.
       * @type {{negative: [], neutral: [], positive: []}}
       */
      const parsedData = Object.entries(this.chartData).reduce((total, [timestamp, { neutral, positive, negative }]) => {
        total.negative.push([parseInt(timestamp), negative])
        total.neutral.push([parseInt(timestamp), neutral])
        total.positive.push([parseInt(timestamp), positive])
        return total
      }, { neutral: [], positive: [], negative: [] })
      return [
        {
          name: this.$t(SENTIMENT_TYPES.positive.label),
          data: parsedData.positive,
          color: blue
        }, {
          name: this.$t(SENTIMENT_TYPES.neutral.label),
          data: parsedData.neutral,
          color: grey
        }, {
          name: this.$t(SENTIMENT_TYPES.negative.label),
          data: parsedData.negative,
          color: salmon
        }]
    },
    redrawChart () {
      this.$chart.update(this.createSeries())
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

</style>
