<template>
  <div class="Media-category-split-charts">
    <div>
      <template v-for="(media, type) in mediaTypes">
        <div
          v-show="chartValues[type]"
          :key="type"
          class="columns is-aligned-middle is-variable-grid is-1"
        >
          <div class="column is-2-tablet is-1-widescreen has-text-centered-tablet">
            <div class="m-h-s columns is-aligned-middle is-multiline is-mobile is-variable-grid is-1">
              <div class="column is-narrow is-12-tablet">
                <div
                  :style="{ backgroundColor: media.color }"
                  class="icon is-medium-mobile is-large-tablet is-round p-a-s"
                >
                  <VIcon :type="media.icon" fallback />
                </div>
              </div>
              <div class="column is-narrow is-12-tablet">
                <h2 class="has-text-weight-bold title is-4">
                  {{ $t(media.label, locale) }}
                </h2>
              </div>
            </div>
          </div>

          <div class="column is-10-tablet is-11-widescreen">
            <div :ref="type" :class="`${type}-chart`" data-testid="MediaCategorySplitCharts__chart" />
          </div>
        </div>
      </template>
    </div>
    <template v-if="!$isEmpty(emptyChartValues)">
      <div class="m-b-s has-text-weight-semibold">
        {{ $t('general.no_data_for', locale) }}
      </div>
      <div class="is-flex-tablet can-wrap">
        <div
          v-for="(chartData, chartType) in emptyChartValues"
          :key="chartType"
          class="p-r-m m-r-m is-flex"
          :style="{ color: mediaTypes[chartType].color }"
        >
          {{ $t(mediaTypes[chartType].label, locale) }}:
          <DelimitedList #default="{ val: subType }" :value="chartData" class="m-l-s">
            {{ $t(mediaCategoryLabels[subType], locale) }}
          </DelimitedList>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BarChart from '@hypefactors/shared/js/components/charts/base/BarChart'
import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { abbrFormatter, abbrCategoryFormatter, moneyFormatter, moneyCategoryFormatter }
  from '@hypefactors/shared/js/components/charts/TooltipFormatter'

import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { MEDIA_CATEGORIES } from '@hypefactors/shared/js/constants/mediaOutletCategories'

/**
 * Generates a chart for each Media Category
 * @module MediaCategorySplitCharts
 */
export default {
  components: {
    DelimitedList,
    VIcon
  },
  props: {
    chartValues: {
      type: Object,
      required: true
    },
    emptyChartValues: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data: () => ({
    charts: {},
    mediaTypes: MEDIA_TYPES,
    mediaCategoryLabels: MEDIA_CATEGORIES
  }),

  computed: {
    ...mapGetters(['convertCurrency']),

    metric () {
      switch (this.type) {
        case 'hfValue':
          return this.$t('general.hypefactors_value', this.locale)
        case 'impressions':
          return this.$t('general.impressions', this.locale)
        case 'clippings':
          return this.$t('general.clippings', this.locale)
      }

      throw new Error('Unexpected value')
    },

    formatter () {
      return this.type === 'hfValue' ? moneyFormatter : abbrFormatter
    },

    categoryFormatter () {
      return this.type === 'hfValue' ? moneyCategoryFormatter : abbrCategoryFormatter
    }
  },

  watch: {
    chartValues (values) {
      Object.keys(values).forEach(type => {
        this.updateChart(type)
        this.updateChartInstance(type)
      })
    },
    type () {
      Object.keys(this.chartValues).forEach(type => {
        this.updateChartInstance(type)
      })
    }
  },

  mounted () {
    Object.keys(this.chartValues).forEach(type => {
      this.initChart(type)
    })
  },

  methods: {
    initChart (type) {
      const ref = this.$refs[type]
      const data = this.extractData(type)
      this.charts['$' + type] = new BarChart(
        ref[0],
        data.categories,
        data.series,
        {
          title: this.metric,
          formatter: this.formatter,
          tooltipFormatter: this.categoryFormatter,
          height: 300,
          pointWidth: 20,
          dataLabels: {
            crop: false,
            overflow: 'none',
            inside: false
          },
          marginLeft: 100,
          plotOptions: {
            bar: {
              color: this.mediaTypes[type].color
            }
          }
        }
      )
    },

    updateChart (type) {
      let extractedData = this.extractData(type)
      this.charts['$' + type].update(extractedData.series, undefined, extractedData.categories)
      // adjust the height of the chart based on the amount of bars
      this.charts['$' + type].instance.setSize(null, extractedData.categories.length * 35 + 100)
    },

    updateChartInstance (type) {
      this.charts['$' + type].instance.update({
        plotOptions: {
          series: {
            dataLabels: {
              formatter: this.formatter
            }
          }
        },
        title: {
          text: this.metric
        },
        tooltip: {
          formatter: this.categoryFormatter
        }
      })
    },

    /**
     * Extracts data for a specific media category
     * @param {string} type
     * @return {{categories: string[], series: {data: number[], name: string}[]}}
     */
    extractData (type) {
      return this.extractSeries(this.chartValues[type], this.metric, type)
    },

    /**
     * Extracts the series from provided data
     * @param {object} data
     * @param {string} seriesName - The name of the series. Currently all are named Hypefactors Value
     * @return {{series: {color: *, data: *, name: *}[], categories: *}}
     * @param {String} type - the media type of the data
     */
    extractSeries (data, seriesName, type) {
      const keys = Object.keys(data)
      return {
        categories: keys.map(key => this.$t(this.mediaCategoryLabels[key], this.locale)),
        color: this.mediaTypes[type].color,
        series: [{
          data: keys.map(key => {
            return this.type === 'hfValue' ? this.convertCurrency(data[key] || 0) : data[key] || 0
          }),
          name: seriesName
        }]
      }
    }
  }
}
</script>

<style
  rel='stylesheet/scss'
  lang='scss'
>
@import '~utils';

</style>
