<template>
  <div class="CoverageSentimentOverTimeDataProvider">
    <sentiment-over-time-chart :chart-data="chartData" />
  </div>
</template>

<script>
import SentimentOverTimeChart from './SentimentOverTimeChart'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @module CoverageSentimentOverTimeDataProvider
 */
export default {
  name: 'CoverageSentimentOverTimeDataProvider',
  components: { SentimentOverTimeChart },
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],
  data () {
    return {
      chartData: {}
    }
  },
  methods: {
    async fetchData () {
      const data = await MetricsService.sentimentsTimeseriesByBrand({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
      // retrieve only the data for the first brand filter.
      // Default to an empty object if something goes wrong
      this.chartData = data.text[this.appliedCoverageFilters.brands[0]] || {}
    }
  }
}
</script>
