<template>
  <div ref="chart" class="DistributionOfClippingsChart" />
</template>

<script>
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'

/**
 * @module DistributionOfClippingsChart
 */
export default {
  name: 'DistributionOfClippingsChart',
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  watch: {
    chartData: 'updateChart'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      this.$chart = new AreaChart(this.$refs.chart, this.createSeries(), {
        title: {
          text: ''
        },
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'top',
          floating: false
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        yAxisLabel: this.$t('general.clippings')
      })
    },
    updateChart () {
      this.$chart.update(this.createSeries())
    },
    createSeries () {
      return this.chartData
    }
  }
}
</script>
