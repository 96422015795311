<template>
  <base-coverage-filters class="CoverageReportGenerator" @ready="$emit('ready', $event)">
    <template #primary>
      <form-field
        v-if="showKeywordSearch"
        left-icon="search"
        icon-class="hf-lg"
      >
        <input
          v-model.lazy="selectedSearchTerms"
          :placeholder="`${$t('forms.search')}...`"
          class="input is-transparent is-size-4 has-text-weight-semibold"
          data-testid="searchClippingsInput"
        >
      </form-field>
    </template>
    <template #tertiary>
      <div class="DashboardFiltersV2-ButtonsContainer">
        <dashboard-filters-v2-item>
          <v-button
            :loading="isExportingClippings"
            class="button is-black is-narrow"
            @click="exportClippings"
          >
            {{ $t('components.reports.generate_report.export_clippings') }}
          </v-button>
        </dashboard-filters-v2-item>
        <dashboard-filters-v2-item>
          <restricted-button
            :loading="isGeneratingReport"
            tag="button"
            target="_blank"
            permission="performance.report"
            action-class="button is-black m-b-s"
            @click="goToReportGenerator"
          >
            {{ $t('components.reports.generate_report.generate_report') }}
          </restricted-button>
        </dashboard-filters-v2-item>
      </div>
    </template>
  </base-coverage-filters>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BaseCoverageFilters from '@hypefactors/shared/js/components/clippings/BaseCoverageFilters'
import { ClippingsApi } from '@/services/api/ClippingsApiService'

import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'

export default {
  name: 'CoverageReportGenerator',
  components: { BaseCoverageFilters },
  props: {
    showKeywordSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isExportingClippings: false,
      isGeneratingReport: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'hasFilter', 'getFilter']),
    ...mapGetters('coverage', ['appliedCoverageFilters']),

    selectedSearchTerms: {
      get () {
        return this.getFilter('search')
      },
      set (value) {
        this.setFilter({
          name: 'search',
          value: value
        })
      }
    }
  },
  methods: {
    ...mapActions(['setFilter']),
    // TODO: Potentially de-duplicate this and place it somewhere
    async goToReportGenerator () {
      this.isGeneratingReport = true

      try {
        const report = await ReportApiService.create(this.activeBrandId, {
          filters: this.appliedCoverageFilters,
          settings: {
            lang: this.$TranslationService.currentLanguage,
            timezone: this.currentUser.timezone
          }
        })

        const reportUrl = this.$getUrl(`/measure/${report.id}`, 'report')

        this.$redirectTo(reportUrl, 0, 'report')
      } catch (error) {
        //
      } finally {
        this.isGeneratingReport = false
      }
    },
    async exportClippings () {
      this.isExportingClippings = true
      try {
        await ClippingsApi.exportClippings(this.appliedCoverageFilters)
        this.$notify.success(this.$t('components.reports.generate_report.export_success'))
      } catch (error) {
        this.$displayRequestError(error, this.$t('components.reports.generate_report.export_error'))
      } finally {
        this.isExportingClippings = false
      }
    }
  }
}
</script>
