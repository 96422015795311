<template>
  <div ref="chart" class="chart MediaTypeSplitChart" data-testid="MediaTypeSplitChart" />
</template>

<script>
import { mapGetters } from 'vuex'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { money, moneyDonutFormatter, abbrDonutFormatter } from '@hypefactors/shared/js/components/charts/TooltipFormatter'
import { abbr } from '@hypefactors/shared/js/utils/numberUtils'

/**
 * Generates the Media Type split chart for Facts and Reports
 * @module MediaTypeSplitChart
 */
export default {
  name: 'MediaTypeSplitChart',

  props: {
    /**
     * @type {Object.<string, {current: {total:number, growth: number}, previous:{total:number, growth: number} }>}
     */
    chartValues: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      $chart: null
    }
  },

  computed: {
    ...mapGetters(['convertCurrency']),

    /**
     * Sums up the current data from the chartValues
     * @return {number}
     */
    summedData () {
      const total = Object.values(this.chartValues).reduce((total, { current }) => {
        return total + current.total
      }, 0)

      return this.type === 'hfValue' ? money(this.convertCurrency(total)) : abbr(total)
    },

    metric () {
      switch (this.type) {
        case 'hfValue':
          return this.$t('general.hypefactors_value', this.locale)
        case 'impressions':
          return this.$t('general.impressions', this.locale)
        case 'clippings':
          return this.$t('general.clippings', this.locale)
      }

      throw new Error('Unexpected value')
    },

    formatter () {
      return this.type === 'hfValue' ? moneyDonutFormatter : abbrDonutFormatter
    }
  },

  watch: {
    chartValues: 'updateChart',
    type: 'updateChartInstance'
  },

  mounted () {
    this.initChart()
  },

  methods: {
    /**
     * Convenience method to get media type props by the media type
     * @param {string} media - media type for which to fetch
     * @param {string} prop - prop to fetch
     * @return {string}
     */
    getProp (media, prop) {
      return MEDIA_TYPES[media][prop]
    },

    /**
     * Creates the series data for the chart
     * @return {{name: string, y: number, color: string}[]}
     */
    createSeries () {
      return Object.entries(this.chartValues).map(([mediaType, values]) => {
        return {
          name: this.$t(this.getProp(mediaType, 'label'), this.locale),
          y: this.type === 'hfValue' ? this.convertCurrency(values.current.total) : values.current.total,
          color: this.getProp(mediaType, 'color')
        }
      })
    },

    /**
     * Creates the series for the chart
     * @return {{name: string, data: ({name: string, y: number, color: string}[]), showInLegend: boolean}[]}
     */
    extractSeries () {
      const data = this.createSeries()
      return [{
        name: this.metric,
        data,
        showInLegend: false
      }]
    },

    initChart () {
      this.$chart = new DonutChart(this.$refs.chart, this.extractSeries(), {
        title: this.summedData,
        subtitle: this.metric,
        formatter: this.formatter,
        yTitleOffset: 10,
        ySubTitleOffset: 25,
        margin: [0, 0, 0, 0],
        titleStyle: {
          fontSize: '2.7rem'
        }
      })
    },

    /**
     * Updates the chart with new series and changes the title.
     * Called when the chartValues prop changes.
     */
    updateChart () {
      this.$chart.update(this.extractSeries(), this.summedData)
    },

    updateChartInstance () {
      this.$chart.instance.update({
        subtitle: {
          text: this.metric
        }
      })
    }
  }
}
</script>
