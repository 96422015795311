import { api } from './ApiInstanceInjector'

export const REPORTS_ENDPOINT = (brandId) => `brands/${brandId}/reports`

export const ReportApiService = {
  fetchAll (brandId, params) {
    return api.getData(`/brands/${brandId}/reports`, params)
  },

  create (brandId, payload) {
    return api.postData(`/brands/${brandId}/reports`, payload)
  },

  fetch (reportId) {
    return api.getData(`/reports/${reportId}`)
  },

  update (reportId, payload) {
    return api.postData(`/reports/${reportId}`, payload)
  },

  delete (reportId) {
    return api.delete(`/reports/${reportId}`)
  },

  copy (reportId, payload) {
    return api.postData(`/reports/${reportId}/copy`, payload)
  },

  generate (reportId, payload) {
    return api.postData(`/reports/${reportId}/generate`, payload)
  },

  fetchReportTemplates (brandId) {
    return api.getData(`brands/${brandId}/reports/templates`)
  },

  createReportTemplate (brandId, payload) {
    return api.postData(`brands/${brandId}/reports/templates`, payload)
  },

  deleteReportTemplate (brandId, reportId) {
    return api.delete(`brands/${brandId}/reports/templates/${reportId}`)
  }
}
