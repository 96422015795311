<template>
  <div class="SentimentSection">
    <div class="columns is-mobile is-multiline is-marginless">
      <div class="column is-12-mobile is-12-tablet is-6-fullhd is-6-report">
        <div class="columns is-mobile is-multiline">
          <div class="column is-12-mobile is-3-tablet is-4-fullhd is-4-report">
            <SentimentTotalsTable
              :locale="locale"
              :sentiments="sentimentValues.text"
            />
          </div>

          <div class="column is-hidden-mobile is-9-tablet is-8-fullhd is-8-report">
            <SentimentDonutChart
              :locale="locale"
              :chart-values="sentimentValues.text"
            />
          </div>
        </div>
      </div>

      <div class="column is-12-mobile is-12-tablet is-6-fullhd is-6-report">
        <ResponsiveTable>
          <SentimentDetailsTable
            v-if="!$isEmpty(countryValues)"
            :chart-values="countryValues"
            :countries="countries"
            :locale="locale"
          />
          <div v-else>
            {{ $t('components.charts.no_data_available', locale) }}
          </div>
        </ResponsiveTable>
      </div>

      <div v-if="emptyCountryData.length" class="column is-12 is-flex is-aligned-middle">
        <div class="m-r-s">
          {{ $t('general.no_data_for', locale) }}:
        </div>
        <delimited-list #default="{ val }" :value="emptyCountryData">
          <span>{{ countries[val] }}</span>
        </delimited-list>
      </div>
    </div>
  </div>
</template>

<script>
import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import ResponsiveTable from '@hypefactors/shared/js/components/tables/ResponsiveTable'
import SentimentDonutChart from './SentimentDonutChart'
import SentimentTotalsTable from './SentimentTotalsTable'
import SentimentDetailsTable from './SentimentDetailsTable'

/**
 * Renders the sentiment chart and tables for Measure/Facts
 * @module SentimentSection
 */
export default {
  components: {
    DelimitedList,
    ResponsiveTable,
    SentimentDonutChart,
    SentimentTotalsTable,
    SentimentDetailsTable
  },

  props: {
    countries: {
      type: Object,
      default: () => ({})
    },
    countryValues: {
      type: Object,
      required: true
    },
    sentimentValues: {
      type: Object,
      required: true
    },
    emptyCountryData: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
