<template>
  <div class="CoverageDistributionOfClippingsDataProvider">
    <distribution-of-clippings-chart :chart-data="chartData" />
  </div>
</template>

<script>
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import DistributionOfClippingsChart from './DistributionOfClippingsChart'

/**
 * @module CoverageDistributionOfClippingsDataProvider
 */
export default {
  name: 'CoverageDistributionOfClippingsDataProvider',
  components: { DistributionOfClippingsChart },
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],
  data () {
    return {
      chartData: []
    }
  },
  methods: {
    async fetchData () {
      const response = await MetricsService.clippingsTimeseriesByCountry({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
      // retrieve only the data for the current active brand
      this.chartData = this.transformData(response.data.data)
    },
    transformData (series) {
      return Object.entries(series.series).map(([isoAlpha2, data]) => {
        return { name: series.countries[isoAlpha2], data }
      })
    }
  }
}
</script>
