<template>
  <table
    class="SentimentTotalsTable is-flex-mobile can-wrap is-aligned-start is-fullwidth table is-transparent has-no-borders has-cells-centered has-cells-vertically-centered"
    data-testid="SentimentTotalsTable"
  >
    <tr
      v-for="sentiment in sentimentOrder"
      :key="sentiment"
      class="is-flex-mobile is-flow-column is-aligned-middle is-expanded"
    >
      <td class="has-text-centered p-h-none">
        <div
          class="icon is-medium-mobile is-large-tablet"
          :style="{ color: getProp(sentiment,'color') }"
        >
          <VIcon
            :type="getProp(sentiment,'icon')"
            fallback
            class="is-width-full"
          />
        </div>

        <div class="is-size-7-mobile">
          {{ $t(getProp(sentiment,'label'), locale) }}
        </div>
      </td>

      <td>
        <div class="has-text-right">
          <div
            v-if="sentiments[sentiment]"
            class="is-size-4-tablet is-size-5-mobile has-text-weight-bold"
            data-testid="sentimentValue"
          >
            {{ percentageFormat(sentiments[sentiment].current) }}
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'
import { SENTIMENT_TYPES, SENTIMENT_ORDER } from '@hypefactors/shared/js/constants/sentimentTypes'

/**
 * Renders the sentiment totals table for Measure/Facts and Report/Measure
 * @module SentimentTotalsTable
 */
export default {
  name: 'SentimentTotalsTable',

  components: {
    VIcon
  },

  mixins: [PercentageMixin],

  props: {
    sentiments: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      sentimentOrder: SENTIMENT_ORDER
    }
  },

  methods: {
    /**
     * Convenience method to get sentiment properties for the provided sentiment
     * @param {string} sentiment - sentiment to fetch for
     * @param {string} prop - prop to fetch
     * @return {string}
     */
    getProp (sentiment, prop) {
      return SENTIMENT_TYPES[sentiment][prop]
    }
  }
}
</script>
