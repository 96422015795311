<template>
  <div class="is-flex-mobile can-wrap is-aligned-middle" data-testid="MediaTypeSplitTotalsTable">
    <template v-for="media in mediaTypeOrder">
      <div
        v-if="hasPropertiesFor(media)"
        :key="media"
        class="mediaType columns is-mobile is-variable-grid is-1 is-marginless"
      >
        <div class="has-text-centered column is-narrow-mobile is-flex-mobile is-aligned-middle">
          <div :style="{ backgroundColor: getProp(media,'color') }" class="icon is-medium-mobile is-large-tablet is-round p-a-s m-h-s">
            <VIcon :type="getProp(media,'icon')" fallback />
          </div>

          <div>{{ $t(getProp(media,'label'), locale) }}</div>
        </div>

        <div class="has-text-right-tablet column is-flex-mobile is-aligned-middle">
          <div class="is-size-4-tablet has-text-weight-bold m-h-s">
            {{ formatTotal(getData(media).total) }}
          </div>

          <div class="is-size-6-tablet has-text-weight-bold">
            {{ percentageFormat(getData(media).percentage) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'
import { MEDIA_TYPE_ORDER, MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

/**
 * Generates the Details table for the media type split on Measure/Facts and Measure/Report
 * @module MediaTypeSplitTotalsTable
 */
export default {
  name: 'MediaTypeSplitTotalsTable',

  components: {
    VIcon
  },

  mixins: [PercentageMixin, AbbrMixin],

  props: {
    chartValues: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      mediaTypeOrder: MEDIA_TYPE_ORDER
    }
  },

  computed: {
    ...mapGetters(['convertCurrency'])
  },

  methods: {
    /**
     * Convenience method to fetch media type values by the type
     * Returns things like Color, label etc.
     * @param {string} media
     * @param {string} prop
     * @return {string}
     */
    getProp (media, prop) {
      return MEDIA_TYPES[media][prop]
    },

    /**
     * Convenience method to fetch chart values by the media type
     * @param {string} media
     * @return {{total:number, percentage: number}}
     */
    getData (media) {
      return this.hasPropertiesFor(media) ? this.chartValues[media].current : null
    },

    /**
     * Checks if the chartValues have a media type
     * @param {string} media
     * @return {boolean}
     */
    hasPropertiesFor (media) {
      return this.chartValues.hasOwnProperty(media)
    },

    formatTotal (total) {
      return this.type === 'hfValue' ? this.abbr(this.convertCurrency(total)) : this.abbr(total)
    }
  }
}
</script>
