<template>
  <ResponsiveTable class="MediaTypeSplitDetailsTable" data-testid="MediaTypeSplitDetailsTable">
    <ShowMoreByItems
      :items="chartValues"
      :items-to-show="7"
      :should-run="!$isReport"
    >
      <template v-slot="{ isVisible }">
        <table
          class="MediaTypeSplitDetailsTable table is-full is-transparent is-bordered"
          data-testid="MediaTypeSplitDetailsTable"
        >
          <thead>
            <tr class="no-hover">
              <td class="is-size-6-mobile" style="vertical-align: bottom">
                {{ $t('forms.country', locale) }}
              </td>

              <template v-for="media in mediaTypeOrder">
                <td
                  v-if="hasPropertiesFor(media)"
                  :key="media"
                  class="has-text-centered"
                >
                  <div
                    :style="{backgroundColor: getProp(media, 'color')}"
                    class="icon is-medium-mobile is-large-tablet is-round p-a-s"
                  >
                    <VIcon :type="getProp(media, 'icon')" fallback />
                  </div>

                  <div class="is-size-7-mobile">
                    {{ $t(getProp(media, 'label'), locale) }}
                  </div>
                </td>
              </template>
            </tr>
          </thead>

          <tbody>
            <template v-for="(values, index) in chartValues">
              <transition :key="index" mode="out-in" name="fade-fast">
                <tr
                  v-if="isVisible(index)"
                  :key="values.name"
                  class="no-hover"
                >
                  <td class="is-size-6-mobile has-text-weight-semibold">
                    {{ values.name }}
                  </td>

                  <template v-for="media in mediaTypeOrder">
                    <td
                      v-if="hasPropertiesFor(media)"
                      :key="media"
                      :class="[ $safeGet(values, media + '.current.total', 0) ? 'has-text-weight-bold': 'has-background-grey-light' ]"
                      class="is-narrow has-text-right"
                    >
                      <div>
                        {{ formatTotal($safeGet(values, media + '.current.total', 0)) }}
                      </div>

                      <div class="is-size-7">
                        {{ percentageFormat($safeGet(values, media + '.current.percentage', 0)) }}
                      </div>
                    </td>
                  </template>
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
      </template>
    </ShowMoreByItems>
  </ResponsiveTable>
</template>

<script>
import { mapGetters } from 'vuex'

import ResponsiveTable from '@hypefactors/shared/js/components/tables/ResponsiveTable'
import ShowMoreByItems from '@hypefactors/shared/js/components/ShowMoreByItems'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { MEDIA_TYPE_ORDER, MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

/**
 * Generates the Details table for the media type split on Measure/Facts and Measure/Report
 * @module MediaTypeSplitDetailsTable
 */
export default {
  name: 'MediaTypeSplitDetailsTable',

  components: {
    ResponsiveTable,
    ShowMoreByItems,
    VIcon
  },

  mixins: [PercentageMixin, AbbrMixin],

  props: {
    chartValues: {
      type: Array,
      required: true
    },
    mediaTotals: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      mediaTypeOrder: MEDIA_TYPE_ORDER
    }
  },

  computed: {
    ...mapGetters(['convertCurrency'])
  },

  methods: {
    /**
     * Checks if a media property exists on the chart data
     * @param {string} media - The media type to check
     * @return {boolean}
     */
    hasPropertiesFor (media) {
      return this.mediaTotals.hasOwnProperty(media)
    },

    /**
     * Convenience method to fetch media type values by the type
     * Returns things like Color, label etc.
     * @param {string} media
     * @param {string} prop
     * @return {string}
     */
    getProp (media, prop) {
      return MEDIA_TYPES[media][prop]
    },

    formatTotal (total) {
      return this.type === 'hfValue' ? this.abbr(this.convertCurrency(total)) : this.abbr(total)
    }
  }
}
</script>
