<template>
  <div
    v-loading="isLoading"
    class="CoverageTopClippingsDataProvider"
  >
    <slot :data="clippings" />
  </div>
</template>

<script>
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @module CoverageTopClippingsDataProvider
 */
export default {
  name: 'CoverageTopClippingsDataProvider',
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    limit: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      clippings: []
    }
  },

  methods: {
    fetchData () {
      return MetricsService.topClippings({
        params: {
          ...this.appliedCoverageFilters,
          limit: this.limit,
          include: ['brand', 'country', 'tags']
        },
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.clippings = response.data.data
        })
    }
  }
}
</script>
