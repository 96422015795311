<template>
  <div ref="chart" class="chart" data-testid="SentimentDonutChart" />
</template>

<script>
import { percentage, round } from '@hypefactors/shared/js/utils/numberUtils'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import { SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'

/**
 * Renders the Sentiment Smiley donut chart on Measure/Facts and Report/Measure
 * @module SentimentDonutChart
 */
export default {
  props: {

    /**
     * @type {Object.<string, { current:number }>}
     */
    chartValues: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    $chart: null
  }),

  computed: {
    summedPositiveData () {
      if (!this.chartValues.positive) return 0
      return this.chartValues.positive.current + this.chartValues.very_positive.current + this.chartValues.neutral.current
    }
  },

  watch: {
    chartValues: 'updateChart'
  },

  mounted () {
    this.initChart()
  },

  methods: {
    /**
     * Convenience method to fetch sentiment properties by the sentiment type
     * @param {string} sentiment - the sentiment type to fetch for
     * @param {string} prop - the sentiment prop to fetch for
     * @return {string} - Returns things like label, color, icon etc.
     */
    getProp (sentiment, prop) {
      return SENTIMENT_TYPES[sentiment][prop]
    },

    initChart () {
      this.$chart = new DonutChart(this.$refs.chart, this.buildSeries(), {
        title: percentage(this.summedPositiveData) + '%',
        subtitle: this.$t('components.reports.sentiment_donut_chart.overall_positive_rating', this.locale),
        yTitleOffset: 30,
        ySubTitleOffset: 35,
        margin: [0, 0, 0, 0],
        titleStyle: {
          fontSize: '3.5rem'
        }
      })
    },

    updateChart () {
      this.$chart.update(this.buildSeries(), percentage(this.summedPositiveData) + '%')
    },

    /**
     * Builds the chart series
     * @return {{name: string, data: {name: string, y: number, color: string}[], showInLegend: boolean}[]}
     */
    buildSeries () {
      const data = this.createData()

      return [{
        name: this.$t('components.reports.sentiment_donut_chart.sentiment_percentage', this.locale),
        data,
        showInLegend: false
      }]
    },

    /**
     * Creates the series data for the Donut Chart
     * @return {{name: string, y: number, color: string}[]}
     */
    createData () {
      return Object.keys(this.chartValues).map(sentimentType => {
        return {
          name: this.$t(this.getProp(sentimentType, 'label'), this.locale),
          y: round(this.chartValues[sentimentType].current * 100),
          color: this.getProp(sentimentType, 'color')
        }
      })
    }
  }
}
</script>
