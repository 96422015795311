<template>
  <div ref="chart" class="CoverageKeyMetricsMapChart" />
</template>

<script>
import MapChart from '@hypefactors/shared/js/components/charts/base/MapChart'

/**
 * @module CoverageKeyMetricsMapChart
 */
export default {
  name: 'CoverageKeyMetricsMapChart',
  props: {
    totals: {
      type: Object,
      default: () => ({
        hfValue: {},
        impressions: {},
        clippings: {}
      })
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      $chart: null
    }
  },
  watch: {
    totals: 'redrawChart'
  },
  mounted () {
    this.mountChart()
  },
  methods: {
    mountChart () {
      this.$chart = new MapChart(
        this.$refs.chart,
        this.createSeries(),
        { title: null }
      )
      this.$chart.instance.mapZoom(0.8)
    },
    createSeries () {
      const impressions = Object.entries(this.totals.impressions)
      const data = impressions.filter(([name, cdata]) => {
        return cdata.current > 0
      }).map(([countryName, countryData]) => ({
        iso_alpha2: countryData.iso_alpha2,
        value: countryData.current, // impressions
        clippings: this.$safeGet(this.totals, `clippings.${countryName}.current`, 0),
        hfValue: this.$safeGet(this.totals, `hfValue.${countryName}.current`, 0)
      }))
      return [{
        joinBy: ['iso-a2', 'iso_alpha2'],
        data,
        name: this.$t('general.impressions', this.locale)
      }]
    },
    redrawChart () {
      this.$chart.update(this.createSeries())
    }
  }
}
</script>
