<template>
  <div class="MediaTypeSplit">
    <div class="columns is-multiline">
      <div class="column is-12-mobile is-12-tablet is-6-fullhd is-6-report">
        <div class="columns is-mobile is-multiline">
          <div class="column is-12-mobile is-3-tablet is-4-fullhd is-4-report">
            <MediaTypeSplitTotalsTable
              v-if="!$isEmpty(dividedFilteredTotals.full)"
              :chart-values="dividedFilteredTotals.full"
              :locale="locale"
              :type="type"
            />
            <div v-else>
              {{ $t('components.charts.no_data_available', locale) }}
            </div>
          </div>

          <div class="column is-hidden-mobile is-9-tablet is-8-fullhd is-8-report">
            <MediaTypeSplitChart
              :chart-values="filteredTotals"
              :locale="locale"
              :type="type"
            />
          </div>
        </div>
      </div>

      <div class="column is-12-mobile is-12-tablet is-6-fullhd is-6-report">
        <MediaTypeSplitDetailsTable
          :chart-values="filledCountryData"
          :locale="locale"
          :type="type"
          :media-totals="chartValues.totals"
        />
      </div>
    </div>
    <div v-if="!$isEmpty(dividedFilteredTotals.empty)" class="is-flex is-aligned-middle can-wrap m-b-s">
      <div class="m-r-s">
        {{ $t('general.no_data_for', locale) }}:
      </div>
      <DelimitedList #default="{ key }" :value="dividedFilteredTotals.empty">
        <span>{{ $t(MEDIA_TYPES[key].label, locale) }}</span>
      </DelimitedList>
    </div>
    <div v-if="emptyCountryData.length" class="is-flex is-aligned-middle can-wrap">
      <div class="m-r-s">
        {{ $t('general.no_data_for', locale) }}:
      </div>
      <DelimitedList :value="emptyCountryData" property="name" />
    </div>
  </div>
</template>

<script>
import _omit from 'lodash/omit'

import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'

import MediaTypeSplitChart from './MediaTypeSplitChart'
import MediaTypeSplitDetailsTable from './MediaTypeSplitDetailsTable'
import MediaTypeSplitTotalsTable from './MediaTypeSplitTotalsTable'
import { UNCLIPPABLE_MEDIA_TYPES, MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

/**
 * Generates the Media Type split charts and tables
 * @module MediaTypeSplit
 * @see module:MediaTypeSplitChart
 * @see module:MediaTypeSplitDetailsTable
 * @see module:MediaTypeSplitTotalsTable
 */
export default {
  name: 'MediaTypeSplit',

  components: {
    DelimitedList,
    MediaTypeSplitChart,
    MediaTypeSplitTotalsTable,
    MediaTypeSplitDetailsTable
  },

  props: {
    chartValues: {
      type: Object,
      required: true
    },
    emptyCountryData: {
      type: Array,
      default: () => []
    },
    filledCountryData: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      default: 'hfValue'
    }
  },
  computed: {
    MEDIA_TYPES: () => MEDIA_TYPES,
    filteredTotals () {
      return _omit(this.chartValues.totals, UNCLIPPABLE_MEDIA_TYPES)
    },
    /**
     * Returns the totals, divided by full or empty
     * @return {{full: {}, empty: {}}}
     */
    dividedFilteredTotals () {
      const empty = {}
      const full = {}
      Object.entries(this.filteredTotals).forEach(([key, value]) => {
        if (value.current.percentage) {
          full[key] = value
        } else {
          empty[key] = value
        }
      })
      return {
        full,
        empty
      }
    }
  }
}
</script>
