<template>
  <page-section
    v-loading="!isCoverageReady"
    class="Facts"
    hide-title
  >
    <template #above-title>
      <coverage-report-generator @ready="onReady" />
    </template>

    <div class="p-v-s">
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-s">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_hypefactors_value')" />
        {{ $t('pages.performance.facts.overview') }}
      </h3>
      <coverage-key-metrics-section-data-provider
        :fetch-when="isCoverageReady"
        :type="type"
        @update:type="handleTypeChange"
      >
        <template v-slot:default="provider">
          <summary-area-chart-totals-section
            :locale="$i18n.locale"
            :is-fetching-timeseries="provider.isFetchingTimeseries"
            :is-fetching-totals="provider.isFetchingTotals"
            :timeseries="provider.timeseries[type]"
            :total-clippings="provider.totals.clippings.totals"
            :total-hf-value="provider.totals.hfValue.totals"
            :total-impressions="provider.totals.impressions.totals"
            :type="type"
            @update:type="provider.handleTypeChange"
          />
        </template>
      </coverage-key-metrics-section-data-provider>
    </div>

    <hr class="m-v-xl">

    <div class="p-v-s">
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_media_types')" />
        {{ $t('pages.performance.facts.media_type_split') }}
      </h3>
      <coverage-media-type-metrics-data-provider :fetch-when="isCoverageReady" :type="type">
        <template v-slot:default="provider">
          <div
            v-loading="provider.isLoading"
            class="CoverageMediaTypeMetricsDataProvider"
          >
            <media-type-split
              :chart-values="provider.chartValues"
              :empty-country-data="provider.emptyCountryData"
              :filled-country-data="provider.filledCountryData"
              :locale="$i18n.locale"
              :type="type"
            />
          </div>
        </template>
      </coverage-media-type-metrics-data-provider>
      <hr class="m-v-xl">
    </div>

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_media_categories')" />
        {{ $t('pages.performance.facts.media_category_split') }}
      </h3>
      <coverage-media-category-data-provider :fetch-when="isCoverageReady" :type="type">
        <template v-slot:default="provider">
          <div
            v-loading="provider.isLoading"
            class="CoverageMediaCategoryDataProvider"
          >
            <media-category-split-charts
              :chart-values="provider.chartData"
              :empty-chart-values="provider.emptyChartData"
              :locale="$i18n.locale"
              :type="type"
            />
          </div>
        </template>
      </coverage-media-category-data-provider>
    </div>

    <hr class="m-v-xl">

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_sentiment')" />
        {{ $t('pages.performance.facts.sentiment') }}
      </h3>
      <coverage-sentiment-data-provider :fetch-when="isCoverageReady" />
    </div>

    <hr class="m-v-xl">

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_sentiment_over_time')" />
        {{ $t('pages.performance.facts.sentiment_over_time') }}
      </h3>
      <coverage-sentiment-over-time-data-provider :fetch-when="isCoverageReady" />
    </div>

    <hr class="m-v-xl">

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.performance.facts.sidebar_description_logo_product')" />
        {{ $t('pages.performance.facts.branding') }}
      </h3>
      <coverage-branding-metrics-data-provider :fetch-when="isCoverageReady" />
    </div>

    <hr class="m-v-xl">

    <div>
      <div>
        <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
          <help-tooltip :content="$t('pages.performance.facts.sidebar_description_top_10_clipping')" />
          {{ $t('pages.performance.facts.top_10_clippings') }}
        </h3>
        <coverage-top-clippings-data-provider #default="{ data: clippings }" :fetch-when="isCoverageReady">
          <top-clippings :clippings="clippings" />
        </coverage-top-clippings-data-provider>
      </div>
    </div>

    <hr class="m-v-xl">

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.measure.metrics_map.sidebar_content')" />
        {{ $t('pages.measure.metrics_map.sidebar_title') }}
      </h3>
      <facts-on-markets-data-provider :fetch-when="isCoverageReady">
        <coverage-key-metrics-map-chart
          slot-scope="{ totals }"
          :totals="totals"
        />
      </facts-on-markets-data-provider>
    </div>

    <hr class="m-v-xl">

    <div>
      <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
        <help-tooltip :content="$t('pages.measure.distribution_of_clippings.tooltip')" />
        {{ $t('pages.measure.distribution_of_clippings.title') }}
      </h3>
      <coverage-distribution-of-clippings-data-provider :fetch-when="isCoverageReady" />
    </div>

    <!-- <hr class="m-v-xl">

    <div class="columns">
      <div class="column">
        <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
          <help-tooltip :content="$t('pages.measure.top_outlets.tooltip')" />
          {{ $t('pages.measure.top_outlets.title') }}
        </h3>
        <coverage-top-media-outlets :fetch-when="isCoverageReady" class="p-h-m" />
      </div>
      <div class="column">
        <h3 class="title is-3 has-text-weight-bold m-t-0 m-b-l">
          <help-tooltip :content="$t('pages.measure.top_tags.tooltip')" />
          {{ $t('pages.measure.top_tags.title') }}
        </h3>
        <coverage-top-tags :fetch-when="isCoverageReady" class="p-h-m" />
      </div>
    </div> -->
  </page-section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import TopClippings from '@hypefactors/shared/js/components/tables/TopClippings'
import CoverageReportGenerator from '@/components/performance/CoverageReportGenerator'
// import CoverageTopTags from '@hypefactors/shared/js/components/charts/extended/CoverageTopTags'
import MediaTypeSplit from '@hypefactors/shared/js/components/charts/extended/mediaTypeMetrics/MediaTypeSplit'
// import CoverageTopMediaOutlets from '@hypefactors/shared/js/components/charts/extended/CoverageTopMediaOutlets'
import CoverageTopClippingsDataProvider from '@hypefactors/shared/js/components/tables/CoverageTopClippingsDataProvider'
import MediaCategorySplitCharts from '@hypefactors/shared/js/components/charts/extended/mediaCategory/MediaCategorySplitCharts'
import CoverageKeyMetricsMapChart from '@hypefactors/shared/js/components/charts/extended/keyMetrics/CoverageKeyMetricsMapChart'
import FactsOnMarketsDataProvider from '@hypefactors/shared/js/components/charts/extended/factsOnMarkets/FactsOnMarketsDataProvider'
import SummaryAreaChartTotalsSection from '@hypefactors/shared/js/components/charts/extended/keyMetrics/SummaryAreaChartTotalsSection'
import CoverageSentimentDataProvider from '@hypefactors/shared/js/components/charts/extended/sentimentMetrics/CoverageSentimentDataProvider'
import CoverageMediaCategoryDataProvider from '@hypefactors/shared/js/components/charts/extended/mediaCategory/CoverageMediaCategoryDataProvider'
import CoverageKeyMetricsSectionDataProvider from '@hypefactors/shared/js/components/charts/extended/keyMetrics/CoverageKeyMetricsSectionDataProvider'
import CoverageSentimentOverTimeDataProvider
  from '@hypefactors/shared/js/components/charts/extended/performance/CoverageSentimentOverTimeDataProvider'
import CoverageDistributionOfClippingsDataProvider
  from '@hypefactors/shared/js/components/charts/extended/performance/CoverageDistributionOfClippingsDataProvider'
import CoverageBrandingMetricsDataProvider
  from '@hypefactors/shared/js/components/charts/extended/brandingMetrics/CoverageBrandingMetricsDataProvider'
import CoverageMediaTypeMetricsDataProvider
  from '@hypefactors/shared/js/components/charts/extended/mediaTypeMetrics/CoverageMediaTypeMetricsDataProvider'

export default {
  components: {
    TopClippings,
    MediaTypeSplit,
    // CoverageTopTags,
    CoverageReportGenerator,
    MediaCategorySplitCharts,
    // CoverageTopMediaOutlets,
    CoverageKeyMetricsMapChart,
    FactsOnMarketsDataProvider,
    CoverageSentimentDataProvider,
    SummaryAreaChartTotalsSection,
    CoverageTopClippingsDataProvider,
    CoverageMediaCategoryDataProvider,
    CoverageBrandingMetricsDataProvider,
    CoverageMediaTypeMetricsDataProvider,
    CoverageKeyMetricsSectionDataProvider,
    CoverageSentimentOverTimeDataProvider,
    CoverageDistributionOfClippingsDataProvider
  },

  data () {
    return {
      type: 'hfValue'
    }
  },
  computed: {
    ...mapState('coverage', ['isCoverageReady'])
  },
  beforeRouteLeave (to, from, next) {
    // remove the coverage ready so facts doesnt fetch prematurely next time we visit
    this.setIsCoverageFactsReady(false)
    next()
  },
  methods: {
    ...mapMutations('coverage', {
      setIsCoverageFactsReady: 'SET_IS_COVERAGE_FACTS_READY'
    }),

    /**
     * Sets the Facts page ready status.
     * Usually called when filters start changing
     * @param {boolean} state
     */
    onReady (state) {
      this.setIsCoverageFactsReady(state)
    },

    handleTypeChange (type) {
      this.type = type
    }
  }
}
</script>
