<template>
  <div
    v-loading="isLoading"
    class="FactsOnMarketsDataProvider"
  >
    <slot :totals="totalsByCountry" />
  </div>
</template>

<script>
import * as MetricsApi from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @typedef {Object.<string, {previous:number, current: number}>} MetricCurrentPrevious
 */

/**
 * @module FactsOnMarketsDataProvider
 */
export default {
  name: 'FactsOnMarketsDataProvider',
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  data () {
    return {
      /**
       * @type {Object.<string, MetricCurrentPrevious>}
       */
      totalsByCountry: {
        hfValue: {},
        impressions: {},
        clippings: {}
      }
    }
  },

  methods: {
    fetchData () {
      return Promise.all([
        MetricsApi.hfValueTotalsByCountry({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token }),
        MetricsApi.impressionsTotalsByCountry({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token }),
        MetricsApi.clippingsTotalsByCountry({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token })
      ])
        .then(([hfValue, impressions, clippings]) => {
          this.totalsByCountry = {
            hfValue: hfValue.data.data,
            impressions: impressions.data.data,
            clippings: clippings.data.data
          }
        })
    }
  }
}
</script>
