<script>
import _set from 'lodash/set'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  name: 'CoverageMediaCategoryDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      chartData: {
        print: {},
        radio: {},
        review: {},
        social: {},
        tv: {},
        web: {}
      },
      emptyChartData: {}
    }
  },

  watch: {
    type: {
      immediate: true,
      handler: generateUniqueWatcher('_fetchIfReady')
    }
  },

  methods: {
    fetchData () {
      let query
      switch (this.type) {
        case 'hfValue':
          query = MetricsService.mediaCategory({
            cancelToken: this.cancelToken.token,
            params: this.appliedCoverageFilters
          })
          break
        case 'impressions':
          query = MetricsService.mediaCategoryByImpressions({
            cancelToken: this.cancelToken.token,
            params: this.appliedCoverageFilters
          })
          break
        case 'clippings':
          query = MetricsService.mediaCategoryByClippings({
            cancelToken: this.cancelToken.token,
            params: this.appliedCoverageFilters
          })
          break
      }
      return query
        .then((response) => {
          const chartDataResponse = response.data.data.current
          const emptyData = {}
          const chartData = {}
          let chartMediaType
          for (chartMediaType in chartDataResponse) {
            if (chartDataResponse.hasOwnProperty(chartMediaType)) {
              const chartValues = chartDataResponse[chartMediaType]
              const areAllChartValuesEmpty = Object.values(chartValues).every(v => v === 0)
              if (areAllChartValuesEmpty) {
                _set(emptyData, [chartMediaType], ['all'])
                continue
              }
              Object.entries(chartValues).forEach(([chartSubType, values]) => {
                if (values) {
                  _set(chartData, [chartMediaType, chartSubType], values)
                } else {
                  if (!emptyData[chartMediaType]) emptyData[chartMediaType] = []
                  emptyData[chartMediaType].push(chartSubType)
                }
              })
            }
          }

          this.chartData = chartData
          this.emptyChartData = emptyData
        })
    }
  },

  render () {
    return this.$scopedSlots.default({
      chartData: this.chartData,
      emptyChartData: this.emptyChartData,
      isLoading: this.isLoading
    })
  }
}
</script>
