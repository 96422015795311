<template>
  <div class="BrandingMetrics">
    <div class="columns is-multiline is-mobile is-variable-grid is-1">
      <div class="column is-6-mobile is-12-tablet is-6-widescreen is-6-report">
        <BrandingMetricsChart
          :locale="locale"
          :totals="brandTotals"
          :table-data="brandData"
          subheading="components.charts.logo_and_product_displayed.logo_displayed"
        />
      </div>

      <div class="column is-6-mobile is-12-tablet is-6-widescreen is-6-report">
        <BrandingMetricsChart
          :locale="locale"
          :totals="productTotals"
          :table-data="productData"
          subheading="components.charts.logo_and_product_displayed.product_displayed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BrandingMetricsChart from './BrandingMetricsChart'

/**
 * Returns the data for the small side tables
 * @param {string} property - property to fetch
 * @param {object} data - response data to fetch from
 * @return {{name: string, current: number, previous: number}[]}
 */
function collectDataFor (property, data) {
  return data.map(data => ({
    name: data.name,
    current: data.current[property] || 0,
    previous: data.previous[property] || 0
  }))
}

/**
 * Returns the data for the small round charts
 * @param {string} property - property to fetch
 * @param {object} data - response data to fetch from
 * @return {{current: number, previous: number}}
 */
function collectTotals (property, data) {
  return {
    current: data.current[property] || 0,
    previous: data.previous[property] || 0
  }
}

/**
 * Transforms data and renders the Product Metrics Charts on the Measure/Facts page
 * @module BrandingMetrics
 * @see module:BrandingMetricsChart
 */
export default {
  name: 'BrandingMetrics',

  components: { BrandingMetricsChart },

  props: {
    chartValues: {
      type: Array,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    brandData () {
      return collectDataFor('brand_displayed', this.chartValues)
    },
    productData () {
      return collectDataFor('product_displayed', this.chartValues)
    },

    brandTotals () {
      return collectTotals('brand_displayed', this.totals)
    },
    productTotals () {
      return collectTotals('product_displayed', this.totals)
    }
  }
}
</script>
