<script>
import { brandTotalsFactory } from '@hypefactors/shared/js/factories/metrics'
import * as MetricsApi from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

export default {
  name: 'CoverageKeyMetricsSectionDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      mutableType: this.type,

      timeseries: {
        hfValue: [],
        impressions: [],
        clippings: []
      },

      totals: brandTotalsFactory(),

      isFetchingTimeseries: false,
      isFetchingTotals: false
    }
  },

  methods: {
    /**
     * Fetch the totals for the Clippings, HfValue and Impressions
     * Used on the 3 boxes in {@link module:SummaryAreaChartTotalsSection}
     * @return {Promise<any>}
     */
    fetchTotals () {
      this.isFetchingTotals = true
      return Promise.all([
        MetricsApi.hfValueTotals({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token }),
        MetricsApi.impressionsTotals({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token }),
        MetricsApi.clippingsTotals({ params: this.appliedCoverageFilters, cancelToken: this.cancelToken.token })
      ])
        .then(([hfValue, impressions, clippings]) => {
          this.totals.hfValue = hfValue.data.data
          this.totals.impressions = impressions.data.data
          this.totals.clippings = clippings.data.data
        })
        .catch((e) => {
          if (this.$api.isCancelToken(e)) return
          this.$displayRequestError(e)
        })
        .finally(() => {
          this.isFetchingTotals = false
        })
    },

    /**
     * Fetches the timeseries data based on the currently selected type
     * @param {string} type
     * @return {Promise<any>}
     */
    fetchTimeseriesByType (type = this.mutableType) {
      this.isFetchingTimeseries = true
      return MetricsApi.fetchTimeseriesByCountryAndByType(type)({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.timeseries[type] = this.transformData(response.data.data)
        })
        .catch((e) => {
          if (this.$api.isCancelToken(e)) return
          this.$displayRequestError(e)
        })
        .finally(() => {
          this.isFetchingTimeseries = false
        })
    },

    fetchData () {
      return Promise.all([
        this.fetchTotals(),
        this.fetchTimeseriesByType()
      ])
    },

    /**
     * Each time the type changes, we need to re-fetch the timeseries data
     * After that is done we update the type
     * @param {string} type
     * @return {Promise<void>}
     */
    async handleTypeChange (type) {
      if (this.isFetchingTimeseries) return
      // TODO: Add caching so we dont refetch data over and over if filters are unchanged
      await this.fetchTimeseriesByType(type)
      this.mutableType = type
      this.$emit('update:type', type)
    },

    /**
     * Transforms the series returned from the api to a format, usable in charts.
     * @param series
     * @returns {{name: string, data: [number, number]}[]}
     */
    transformData (series) {
      return Object.entries(series.series).map(([isoAlpha2, data]) => {
        return { name: series.countries[isoAlpha2], data }
      })
    }
  },

  render () {
    return this.$scopedSlots.default({
      isFetchingTimeseries: this.isFetchingTimeseries,
      isFetchingTotals: this.isFetchingTotals,
      timeseries: this.timeseries,
      totals: this.totals,
      handleTypeChange: this.handleTypeChange
    })
  }
}
</script>
