<script>
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  name: 'CoverageMediaTypeMetricsDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    type: {
      type: String,
      default: 'hfValue'
    }
  },

  data () {
    return {
      chartValues: {
        countries: [],
        totals: {}
      },
      emptyCountryData: [],
      filledCountryData: []
    }
  },

  watch: {
    type: {
      immediate: true,
      handler: generateUniqueWatcher('_fetchIfReady')
    }
  },

  methods: {
    fetchData () {
      switch (this.type) {
        case 'hfValue':
          return MetricsService.mediaType({
            params: this.appliedCoverageFilters,
            cancelToken: this.cancelToken.token
          })
            .then((response) => {
              this.sanitizeData(response.data.data)
            })
        case 'impressions':
          return MetricsService.mediaTypeByImpressions({
            params: this.appliedCoverageFilters,
            cancelToken: this.cancelToken.token
          })
            .then((response) => {
              this.sanitizeData(response.data.data)
            })
        case 'clippings':
          return MetricsService.mediaTypeByClippings({
            params: this.appliedCoverageFilters,
            cancelToken: this.cancelToken.token
          })
            .then((response) => {
              this.sanitizeData(response.data.data)
            })
      }
    },
    /**
     *
     * @param {{ countries:[], totals: Object }} data
     */
    sanitizeData (data) {
      this.chartValues = data
      const emptyCountryData = []
      const filledCountryData = []
      data.countries.forEach((country) => {
        const countryClone = { ...country }
        delete countryClone.name
        const areAllCountryValuesEmpty = Object.entries(countryClone).every(([key, value]) => value.current && value.current.total === 0)
        if (areAllCountryValuesEmpty) {
          emptyCountryData.push(country)
        } else {
          filledCountryData.push(country)
        }
      })
      this.emptyCountryData = emptyCountryData
      this.filledCountryData = filledCountryData
    }
  },

  render () {
    return this.$scopedSlots.default({
      chartValues: this.chartValues,
      emptyCountryData: this.emptyCountryData,
      filledCountryData: this.filledCountryData,
      isLoading: this.isLoading
    })
  }
}
</script>
