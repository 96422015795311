<template>
  <div class="BrandingMetricsChart columns is-aligned-middle">
    <div class="column is-6">
      <div class="chart-holder">
        <div ref="chart" class="chart" data-testid="BrandingMetricsChart__chart" />
      </div>
    </div>

    <div class="column">
      <div class="is-bold is-size-5 is-size-6-mobile">
        {{ $t(subheading, locale) }}
      </div>

      <div data-testid="BrandingMetricsChart__table">
        <table v-if="tableData.length" class="table has-no-borders is-transparent is-size-6-mobile">
          <tr
            v-for="data in tableData"
            :key="data.name"
          >
            <td class="p-l-none p-v-xs">
              {{ percentageFormat(data.current) }}
            </td>
            <td class="p-v-xs">
              {{ data.name }}
            </td>
          </tr>
        </table>
        <div v-else>
          {{ $t('components.charts.no_data_available', locale) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@hypefactors/shared/js/utils/numberUtils'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

/**
 * Generates the Product Metrics charts and tables for Measure/Facts
 * @module BrandingMetricsChart
 */
export default {
  name: 'BrandingMetricsChart',

  mixins: [PercentageMixin],

  props: {
    subheading: {
      type: String,
      default: ''
    },

    /**
     * @type {{name: string, current: number, previous: number}[]}
     */
    tableData: {
      type: Array,
      required: true
    },

    /**
     * @type {{type: ObjectConstructor, required: boolean}}
     */
    totals: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  watch: {
    totals: 'updateChart'
  },

  mounted () {
    this.initChart()
  },

  methods: {
    initChart () {
      const data = this.createSeries()

      this.$chart = new DonutChart(this.$refs.chart, data.series, {
        title: data.title,
        height: 300,
        yTitleOffset: 20,
        margin: [0, 0, 20, 0],
        titleStyle: {
          fontSize: '2.5em'
        }
      })
    },

    /**
     * Called when the chart values prop changes
     */
    updateChart () {
      const data = this.createSeries()

      this.$chart.update(data.series, data.title)
    },

    /**
     * Creates the time series for the donut charts
     * @return {{title: string, series: {name: *, data: {name: *, y: *}[], showInLegend: boolean, dataLabels: {enabled: boolean, format: string}}[]}}
     */
    createSeries () {
      const value = this.totals.current
      const totalDisplayedPercentage = round(value * 100)
      const totalNotDisplayedPercentage = round(100 - (value * 100))
      const data = [
        {
          name: this.$t('components.charts.logo_and_product_displayed.present', this.locale),
          y: totalDisplayedPercentage
        },
        {
          name: this.$t('components.charts.logo_and_product_displayed.not_present', this.locale),
          y: totalNotDisplayedPercentage
        }
      ]

      return {
        title: totalDisplayedPercentage + '%',
        series: [{
          name: this.$t('general.logo', this.locale),
          data: data,
          showInLegend: true,
          dataLabels: {
            enabled: false,
            format: '{y} %'
          }
        }]
      }
    }
  }
}
</script>
