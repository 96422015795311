<template>
  <div>
    <slot
      name="default"
      :is-visible="isItemVisible"
    />

    <slot
      name="button"
      :toggle="toggle"
      :is-visible="isTogglerVisible"
      :is-expanded="isExpanded"
    >
      <v-button v-if="isTogglerVisible" class="is-text has-text-primary is-narrow" @click="toggle()">
        {{ !isExpanded ? 'Show more' : 'Show less' }}
      </v-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ShowMoreByItems',

  props: {
    items: {
      type: [Object, Array],
      required: true
    },

    itemsToShow: {
      type: Number,
      required: true
    },

    shouldRun: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      limit: 0
    }
  },

  computed: {
    isTogglerVisible () {
      if (!this.shouldRun) {
        return false
      }

      return this.getItemsLength() > this.itemsToShow
    },

    isExpanded () {
      return this.limit !== this.itemsToShow
    }
  },

  created () {
    this.limit = this.itemsToShow
  },

  methods: {
    getItemsLength () {
      return !Array.isArray(this.items)
        ? Object.keys(this.items).length
        : this.items.length
    },

    toggle () {
      this.limit = this.limit === this.itemsToShow
        ? this.getItemsLength()
        : this.itemsToShow
    },

    isItemVisible (id) {
      if (!this.shouldRun) {
        return true
      }

      return id < this.limit
    }
  }
}
</script>
