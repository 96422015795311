<template>
  <div
    v-loading="isLoading"
    class="CoverageBrandingMetricsDataProvider"
  >
    <BrandingMetrics
      :locale="locale"
      :totals="chartData.totals"
      :chart-values="filledCountryData"
    />
    <div v-if="emptyCountryData.length" class="is-flex is-aligned-middle m-t-m can-wrap">
      <div class="m-r-s">
        {{ $t('general.no_data_for', locale) }}:
      </div>

      <DelimitedList :value="emptyCountryData" property="name" />
    </div>
  </div>
</template>

<script>
import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'
import BrandingMetrics from './BrandingMetrics'

/**
 * Fetches data for the Product Metrics charts on Measure/Facts
 * @module CoverageBrandingMetricsDataProvider
 */
export default {
  name: 'CoverageBrandingMetricsDataProvider',
  components: {
    DelimitedList,
    BrandingMetrics
  },
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    locale: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      chartData: {
        countries: [],
        totals: {
          current: {},
          previous: {}
        }
      },
      emptyCountryData: [],
      filledCountryData: []
    }
  },

  methods: {
    fetchData () {
      return MetricsService.branding({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.sanitizeData(response.data.data)
        })
    },
    sanitizeData (data) {
      this.chartData = data
      const filledCountryData = []
      const emptyCountryData = []
      this.chartData.countries.forEach((chartData) => {
        if (!chartData.current.brand_displayed && !chartData.current.product_displayed) {
          emptyCountryData.push(chartData)
        } else {
          filledCountryData.push(chartData)
        }
      })
      this.emptyCountryData = emptyCountryData
      this.filledCountryData = filledCountryData
    }
  }
}
</script>
