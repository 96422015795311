<template>
  <div
    v-loading="isLoading"
    class="CoverageSentimentDataProvider"
  >
    <SentimentSection
      :locale="locale"
      :countries="chartValues.byCountry.countries"
      :country-values="filledCountryData"
      :sentiment-values="chartValues.bySentiment"
      :empty-country-data="emptyCountryData"
    />
  </div>
</template>

<script>
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

import SentimentSection from './SentimentSection'

/**
 * Fetches the data for Sentiments charts and passes it down to the {@link module:SentimentSection}
 * @module CoverageSentimentDataProvider
 */
export default {
  name: 'CoverageSentimentDataProvider',

  components: { SentimentSection },

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  props: {
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      chartValues: {
        byCountry: {
          countries: {},
          sentiments: {
            text: {},
            picture: {}
          }
        },
        bySentiment: {
          text: {},
          picture: {}
        }
      },
      filledCountryData: {},
      emptyCountryData: []
    }
  },

  methods: {
    fetchData () {
      return Promise.all([
        this.fetchByCountry(),
        this.fetchBySentiment()
      ])
    },

    fetchByCountry () {
      return MetricsService.sentimentsByCountries({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.chartValues.byCountry = response.data.data
          this.sanitizeCountryData(this.chartValues.byCountry.sentiments.text)
        })
    },

    fetchBySentiment () {
      return MetricsService.sentimentsBySentiments({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.chartValues.bySentiment = response.data.data
        })
    },
    sanitizeCountryData (data) {
      let emptyCountryData = []
      let filledCountryData = {}
      Object.entries(data).forEach(([key, sentiments]) => {
        const isEmpty = Object.values(sentiments).every(value => value.current === 0)
        if (isEmpty) {
          emptyCountryData.push(key)
        } else {
          filledCountryData[key] = sentiments
        }
      })
      this.filledCountryData = filledCountryData
      this.emptyCountryData = emptyCountryData
    }
  }
}
</script>
