<template>
  <ShowMoreByItems
    :items="chartValues"
    :items-to-show="7"
    :should-run="!$isReport"
  >
    <template v-slot="{ isVisible }">
      <table
        class="SentimentDetailsTable table is-full is-transparent is-bordered"
        data-testid="SentimentDetailsTable"
      >
        <thead>
          <tr class="no-hover">
            <td class="cell-aligned-bottom is-size-6-mobile">
              {{ $t('forms.country', locale) }}
            </td>

            <td
              v-for="sentimentType in sentimentOrder"
              :key="sentimentType"
              :style="{color: getProp(sentimentType, 'color') }"
              class="has-text-centered"
            >
              <div class="icon is-medium-mobile is-large-tablet">
                <VIcon :type="getProp(sentimentType, 'icon')" fallback />
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          <template v-for="(values, country, index) in chartValues">
            <transition :key="index" mode="out-in" name="fade-fast">
              <tr
                v-if="isVisible(index)"
                :key="country.name"
                class="no-hover"
                data-testid="chartValuesRow"
              >
                <td class="is-size-6-mobile">
                  <strong>{{ countries[country] }}</strong>
                </td>

                <td
                  v-for="sentimentType in sentimentOrder"
                  :key="sentimentType"
                  :class="{ 'has-background-grey-light': !(values[sentimentType] && values[sentimentType].current) }"
                  class="is-narrow has-text-right"
                >
                  <div
                    v-if="values[sentimentType]"
                    :class="{ 'has-text-weight-bold': values[sentimentType].current }"
                  >
                    {{ percentageFormat(values[sentimentType].current) }}
                  </div>
                </td>
              </tr>
            </transition>
          </template>
        </tbody>
      </table>
    </template>
  </ShowMoreByItems>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'
import ShowMoreByItems from '@hypefactors/shared/js/components/ShowMoreByItems'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'
import { SENTIMENT_ORDER, SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'

/**
 * Renders the Sentiments details table on Measure/Facts And Report/Measure
 * @module SentimentDetailsTable
 */
export default {
  name: 'SentimentDetailsTable',

  components: {
    VIcon,
    ShowMoreByItems
  },

  mixins: [PercentageMixin],

  props: {
    /**
     * @type {Object.<string, Object.<string, { current:number, previous: number }>>}
     */
    chartValues: {
      type: [Object, Array],
      required: true
    },
    countries: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      sentimentOrder: SENTIMENT_ORDER
    }
  },

  methods: {
    /**
     * Convenience method to fetch sentiment props by sentiment type
     * @param {string} sentiment - sentiment type
     * @param {string} prop - property to fetch
     * @return {string}
     */
    getProp (sentiment, prop) {
      return SENTIMENT_TYPES[sentiment][prop]
    }
  }
}
</script>
